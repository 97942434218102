.container-style{
    height: 100%;
    position: relative;
}

.description{
    width: 380px;
    position: absolute;
    z-index: 998;
    top: 23%;
    left: 1%;

    @media screen and (max-width: 500px) {
        left: 50%;
        transform: translateX(-50%);
    }
}

.description h1{
    font-size: 46px;
}

.description p{
    font-size: 22px;
}
.carousel-boullt{
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-boullt span{
    width: 45px;
    height: 12px;
    background-color: #0f0f0f;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 3px 2px 2px rgba (73, 72, 72, 0.338);
}