* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  padding: 0;
}


.App {
  /* background-color: #333; */
  height: 100vh;
  text-align: center;
}

